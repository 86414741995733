<template>
  <div class="Profile_Parent panel Administration_panel">
    <div class="inner_panel">
      <div
        style="display: flex; justify-content: flex-start; align-items: center"
      >
        <div class="protectedStatus">
          <img @load="imgLoaded" src="../assets/media/protected.svg" alt="" />
          {{ changeRoleView(getUserRole()) }}
        </div>
      </div>
      <h4 style="margin-bottom: 0.5rem">Instituții</h4>
      <div
        :class="0 ? 'Administration_section_loading' : ''"
        class="Administration_panel_section"
      >
        <div class="Administration_panel_block">
          <FileInput
            style="margin-top: -1rem;"
            :blobMode="true"
            :initialImage="institutionLogo"
            :prepareBlobName="
              (file) =>
                this.isString(file.name)
                  ? `file.${file.name.split('.').at(-1)}`
                  : 'File'
            "
            @save="saveLogo"
          />
          <h5 v-if="showTotalRecords">Total instituții: {{ totalRecords }}</h5>
          <div class="form-flex">
            <div class="form-group">
              <b class="asterisk">Denumirea instituției</b>
              <input v-model="models.name" type="text" class="vInput" />
            </div>
            <div class="form-group">
              <b class="asterisk">CIF</b>
              <input v-number v-model="models.cif" type="text" class="vInput" />
            </div>
            <div class="form-group">
              <b class="asterisk">Email-ul instituției</b>
              <input v-model="models.email" type="text" class="vInput" />
            </div>
          </div>
          <div class="form-flex" style="margin-top: 1rem">
            <div class="form-group form-half">
              <b class="asterisk">Telefon</b>
              <vue-tel-input v-model="models.phone" @input="checkPhone" />
            </div>
            <div class="form-group">
              <b class="asterisk">Adresa instituției</b>
              <input v-model="models.address" type="text" class="vInput" />
            </div>
            <div class="form-group form-half">
              <b class="asterisk">Tip administrare</b>
              <v-select
                v-model="models.type"
                :options="[
                  'Administrație publică locală',
                  'Administrație publică centrală',
                ]"
                :searchable="false"
                :disabled="getUserRole() != 'superadmin'"
                :style="
                  getUserRole() != 'superadmin'
                    ? 'background: transparent;'
                    : ''
                "
              />
            </div>
          </div>
          <div style="margin-top: 1rem;">
            <label
              for="ohvc9uqz"
              style="color: #585858;font-weight: 500;cursor: pointer;"
            >
              <input
                v-model="models.approveMode"
                type="checkbox"
                id="ohvc9uqz"
              />
              <span> Activează modul de aprobare</span>
            </label>
          </div>

          <div style="margin-top: 1rem;">
            <label
              for="ohvc9uqx"
              style="color: #585858;font-weight: 500;cursor: pointer;"
              v-tooltip="
                !models.approveMode
                  ? 'Necesită activarea modului de aprobare'
                  : ''
              "
            >
              <input
                v-model="models.avizoMode"
                type="checkbox"
                id="ohvc9uqx"
                :disabled="!models.approveMode"
              />
              <span> Activează modul de avizare</span>
            </label>
          </div>

          <div class="right">
            <div @click="saveData()" class="Button1">
              <div class="icon">
                <img
                  @load="imgLoaded"
                  src="../assets/media/for_table/circle_ADD_new_etap.svg"
                  alt=""
                />
              </div>
              <span class="Btn_content">
                {{ btnText }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  INSTITUTION_ADD,
  INSTITUTION_MODIFY,
  INSTITUTION_UPLOAD,
  INSTITUTION_DOWNLOAD,
} from "@/api.js";

import FileInput from "@/components/FileInput";

const defaultData = () => ({
  name: "",
  email: "",
  address: "",
  cif: "",
  phone: "",
  type: "",
  avizoMode: false,
  approveMode: false,
});
const checker = window["validator"] || (() => false);
const safeApi = window["apiTimeout"](1000, false, true);

export default {
  props: {
    options: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  components: {
    FileInput,
  },
  data() {
    return {
      models: defaultData(),
      phoneValide: false,
      institutionLogo: "",
      institutionLogoWasEdited: false,
      editId: null,
    };
  },
  watch: {
    "models.approveMode"(val) {
      if (!val) {
        this.models.avizoMode = false;
      }
    },
    options() {
      this.initData();
    },
  },
  methods: {
    initData() {
      if (this.action == "edit") {
        const data = this.options.data;

        if (this.isObject(data)) {
          this.editId = this.options.data?.id ?? null;
          this.options.data = this.readAndSyncData(data);
        }

        this.$nextTick(this.syncLogo);
      }
    },
    getModelsData() {
      const excludes = [];
      const models = { ...this.models };

      excludes.forEach((e) => delete models[e]);

      return models;
    },
    isValid(useAlerts) {
      const y = checker.call(this.models, ["avizoMode", "approveMode"]);

      if (!this.models.approveMode) {
        this.models.avizoMode = false;
      }

      const w = this.validateEmail(this.models.email);
      const x = w && this.phoneValide && y;
      const q =
        x &&
        (this.isEdited(this.options.data, this.models) ||
          this.institutionLogoWasEdited);

      const alert = (msg) =>
        this.$toastr.w(msg || "Completați toate câmpurile obligatorii.");

      if (useAlerts) {
        if (!y) alert();
        else if (!w) alert("Introduceți un email corect.");
        else if (!this.phoneValide) alert("Introduceți telefonul.");
        else if (this.action == "edit" && !q)
          alert("Nu s-au găsit modificări.");
      }

      return this.action == "edit" ? q : x;
    },
    uploadLogo(id, file, successCallback = () => {}, errorCallback = () => {}) {
      if (!Number.isInteger(id) || !file) return;

      const formData = new FormData();
      formData.append("file", this.institutionLogo);

      INSTITUTION_UPLOAD(id, formData)
        .then((r) => {
          if (this.checkHttpStatusCode(r?.meta?.HttpStatusCode)) {
            successCallback();
          } else {
            errorCallback();
          }
        })
        .catch(errorCallback);
    },
    createInstitution() {
      const sendData = this.getModelsData();

      const error = (msg) => {
        this.$toastr.e(
          msg || "Crearea instituției a eșuat. Încercați mai târziu."
        );
        this.setLoad();
      };
      const success = () => {
        this.$toastr.s("Instituția a fost creată.");
        this.$emit("closePanel");
        this.setLoad();
      };

      this.setSafeLoad(12000);

      INSTITUTION_ADD(sendData)
        .then((res) => {
          if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            if (this.institutionLogo) {
              const id = res.data?.result?.id;
              const logoError = (msg) => {
                this.$toastr.e(
                  msg || "Logotipul instituției nu a fost salvat."
                );
              };

              if (Number.isInteger(id)) {
                this.uploadLogo(id, this.institutionLogo, success, logoError);
              } else {
                success();
                logoError();
              }
            } else {
              success();
            }
          } else {
            error();
          }
        })
        .catch(error);
    },
    editInstitution() {
      const sendData = this.getEdited(this.options.data, this.models);

      if (this.institutionLogoWasEdited && this.institutionLogo) {
        sendData["logo"] = this.institutionLogo;
      }

      if (!this.isObjEmpty(sendData)) {
        const success = (msg) => {
          this.$toastr.s(msg || "Instituția a fost editată.");
          this.$emit("closePanel");
          this.setLoad();
        };
        const error = (msg) => {
          this.$toastr.e(
            msg || "Editarea instituției a eșuat. Încercați mai târziu."
          );
          this.setLoad();
        };

        this.setSafeLoad(12000);
        if (sendData["logo"]) {
          const file = sendData["logo"];
          delete sendData["logo"];

          const logoError = (msg) => {
            this.$toastr.e(msg || "Logotipul instituției nu a fost salvat.");
            this.setLoad();
          };

          this.uploadLogo(
            this.editId,
            file,
            () => {
              if (this.isObjEmpty(sendData)) {
                success("Logotipul instituției a fost salvat.");
              }
            },
            logoError
          );
        }

        if (!this.isObjEmpty(sendData)) {
          INSTITUTION_MODIFY(this.editId, sendData)
            .then((res) => {
              if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                success();
              } else {
                error();
              }
            })
            .catch(error);
        }
      }
    },
    saveLogo(file) {
      if (!file || typeof file != "object") return;

      this.institutionLogoWasEdited = true;
      this.institutionLogo = file;
    },
    saveData() {
      if (this.isValid(true)) {
        const fnMap = {
          create: "createInstitution",
          edit: "editInstitution",
        };
        const findFn = fnMap[this.action];

        if (findFn && typeof this[findFn] === "function") {
          safeApi(this[findFn]);
        }
      }
    },
    checkPhone(...x) {
      const y = x[1];
      this.phoneValide = !!y?.valid;
    },
    readAndSyncData(data) {
      if (!this.isObject(data)) return;

      const x = this.models;
      data = { ...data };

      Object.keys(x).forEach((key) => {
        if (data.hasOwnProperty(key)) x[key] = data[key];
      });

      return { ...x };
    },
    syncLogo() {
      if (Number.isInteger(this.editId)) {
        const error = (msg) => {
          if (msg) this.$toastr.e(msg);
          this.setLoad();
        };

        this.setSafeLoad(12000);
        INSTITUTION_DOWNLOAD(this.editId)
          .then((blob) => {
            if (blob?.type && blob.type != "application/json") {
              this.institutionLogo = blob;
              this.setLoad();
            } else {
              error();
            }
          })
          .catch(error);
      }
    },
  },
  computed: {
    action() {
      return this.options.action || "create";
    },
    btnText() {
      return (
        {
          create: "Creează",
          edit: "Modifică",
        }[this.action] || "Salvează"
      );
    },
    showTotalRecords() {
      return Number.isInteger(this.totalRecords);
    },
    totalRecords() {
      return this.options.totalRecords ?? null;
    },
  },
  created() {
    this.initData();
  },
};
</script>

<style src="../assets/profile.css"></style>
<style src="../assets/administration.css"></style>
