export default {
  data() {
    return {
      PERMISIONS: {
        list: false,
        viewDetails: false,
        create: false,
        edit: false,
        delete: false,
        viewDepartments: false
      }
    }
  },
  created() {
    const baseRule = 'institutions'

    this.initPermision(`departments.get`,'viewDepartments')
    this.initPermision(`${baseRule}.get`,'list')
    this.initPermision(`${baseRule}.view_details`,'viewDetails')
    this.initPermision(`${baseRule}.post`,'create')
    this.initPermision(`${baseRule}.patch`,'edit')
    this.initPermision(`${baseRule}.delete`,'delete')
  }
}