<template>
  <div style="padding-left: 1.75rem">
    <div class="col2_head">
      <router-link :to="'/'" class="breadcrumb_home">Acasă /</router-link>
      {{ $route.name }}
      {{ stateLabels[0] }}
      <div class="subHeadTitle">{{ stateLabels[1] }}</div>
      <div class="right" v-if="PERMISIONS.create">
        <div class="right" style="margin-top: 1.2rem">
          <div v-if="showPanel" @click="closePanel()" class="goBack">
            <img src="../assets/media/vectors/arrow_back.svg" alt="">
          </div>
          <div v-else-if="PERMISIONS.create" @click="createInstitution()" class="Button1">
            <div class="icon">
              <img @load="imgLoaded" src="../assets/media/for_table/circle_ADD_new_etap.svg" alt="">
            </div>
            <span class="Btn_content">
              Adaugă instituție
            </span>
          </div>
        </div>
      </div>
    </div>
    <Panel v-if="showPanel" :options="panelData" @closePanel="closePanel" />
    <template v-else>
      <template v-if="PERMISIONS.list">
        <table-parent
          :reloadCount="reloadTable"
          :tableHead="tableHead"
          :prepareFn="prepareData"
          :apiModule="tableModule"
          :apiModuleName="'INSTITUTIONS_PAGINATED'"
          :tableMinimizeHead="true"
          :tableSpread="true"
          @Btn2="editInstitution" 
          @Btn3="deleteInstitution" 
          @Btn15="viewDepartments"
          @Btn29="viewUsers"
          @totalRecords="x => totalRecords = x"
        />
      </template>
      <template v-else>
        <h3 style="display: flex; flex-direction: column; align-items: center">
          <span style="font-size: 4rem">
            <i class="fas fa-exclamation-triangle"></i>
          </span>
          Acces interzis
        </h3>
      </template>
    </template>
  </div>
</template>

<script>
import PermisionsMixin from '@/mixins/institutionsPermMixin.js'
import updateRoleStatus from '../mixins/updateUserRoleStatus.js'
import { INSTITUTIONS_PAGINATED, INSTITUTION_DELETE } from "../api.js";
import DialogModal from '@/components/DialogModal'

import TableParent from '@/components/TableParent'
import Panel from '@/components/InstitutionsPanel'

export default {
  name: 'Institutions',
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },
  mixins: [PermisionsMixin, updateRoleStatus],
  components: {
    TableParent, Panel
  },
  data() {
    return {
      tableHead: [
        {title:'Denumire',sort:true,
        queryKey: 'name',
        bottomHead: {
            quikFiltre: true
        }},
        {title:'CIF',sort:true,
        queryKey: 'cif',
        bottomHead: {
            quikFiltre: true
        }},
        {title:'Email',
        queryKey: 'email',
        bottomHead: {
            quikFiltre: true
        }},
        {title:'Adresa',
        queryKey: 'address',
        bottomHead: {
            quikFiltre: true
        }},
        {title:'Tipul',
        queryKey: 'type',
        bottomHead: {
            quikFiltre: 'list',
            options: ['Administrație publică locală','Administrație publică centrală']
        }}
      ],
      tableModule: INSTITUTIONS_PAGINATED,
      panelData: false,
      totalRecords: 0,
      reloadTable: 0
    }
  },
  methods: {
    reload() {
      this.reloadTable++
    },
    prepareData(row) {
      const preparation = [
        row.name || '-',row.cif || '-', row.email || '-',row.address || '-', row.type || '-'
      ]

      const uuid = row.createdByUser
      const x = this.PERMISIONS
      const prepareBtn = []

      if(x.edit === true || this.checkOwner(x.edit, uuid)) {
        prepareBtn.push(2)
      }
      if(x.delete === true || this.checkOwner(x.delete, uuid)) {
        prepareBtn.push(3)
      }
      if(x.viewDepartments) {
        prepareBtn.push(15)
      }
      prepareBtn.push([
        29,
        "Vezi utilizatorii din instituție",
        ["fas fa-user", "font-size:2.2rem;color:#39f;"],
      ])
      if(prepareBtn.length) {
        preparation.push([prepareBtn])
      }
      
      return preparation
    },
    closePanel() {
      this.panelData = false
    },
    openModal(options) {

      if(this.isObject(options)) {
        this.simulateLoad(() => this.panelData = options)
      }

    },
    createInstitution() {
      this.openModal({
        action: 'create',
        totalRecords: this.totalRecords
      })
    },
    editInstitution(data) {
      if(!this.isObject(data)) return

      this.openModal({
        action: 'edit',
        data
      })
    },
    deleteInstitution(data) {
      if(!Number.isInteger(data?.id)) return

      this.$modal.show(
        DialogModal,
        {
          target: 'InstitutionDelete',
          title: 'Ștergere instituție',
          content: '<span style="color:#f44336;display: block;margin-top: 2rem;"><i class="fas fa-exclamation-circle" style="font-size: 6rem;"></i> <p style="font-weight: 500;font-size: 2.2rem;">Ești sigur că vrei să ștergi instituția selectată?</p></span>',
          closeBtnText: 'Anulează',
          button: {
            type: 3,
            value: 'Șterge',
            handler: () => {
              const error = msg => {
                this.$toastr.e(msg || 'Ștergerea instituției a eșuat.')
                this.reload()
                this.setLoad()
              }

              this.setSafeLoad(12000)
              INSTITUTION_DELETE(data.id).then(res => {
                if(res?.statusCode == 402) {
                  this.$toastr.w('Instituția nu poate fi ștearsă din cauza departamentelor atașate de aceasta.')
                  this.setLoad()
                  return
                }
                if(this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                  this.$toastr.s('Instituția a fost ștearsă.')
                  this.reload()
                  this.setLoad()
                } else {
                  error()
                }
              }).catch(error)
            }
          }
        },
        {
          name: 'InstitutionDelete',
          adaptive: true,
          width: '650',
          height: '400'
        }
      )
    },
    viewDepartments(data) {
      if(!this.isObject(data)) return
8
      this.$router.push(`/${this.$route.params.navigation}/departamente` + (data.name ? `?institution=${data.name}` : ''))
    },
    viewUsers(data) {
      if(!this.isObject(data)) return
8
      this.$router.push(`/${this.$route.params.navigation}/utilizatori` + (data.id ? `?from_institution=${data.id}` : ''))
    },
  },
  computed: {
    showPanel() {
      return !! this.panelData
    },
    stateLabels() {
      return this.showPanel ? [
        ' / Administrare',
        'Panou de Administrare'
      ] : ['', this.$route.name]
    }
  },
  created() {
    if(!this.PERMISIONS.list) {
      return
    }
    
    const canViewButtonsColumn = ['viewDetails','edit','delete']

    this.updateRoleStatus()
    if(this.checkParamsInObj(this.PERMISIONS, canViewButtonsColumn)) {
      this.tableHead.push({spacer:true,minimize:1,fixed:{
        right: true
      },bottomHead: {
          quikFiltre: 'clear'
      }})
    }
  }
}
</script>